import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { LocalContext } from 'context';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

function PollDocumentation() {
  const { theme, selectedPollDocumentation } = useContext(LocalContext);
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(src/data/docs)/" } }
        sort: { fields: id }
      ) {
        edges {
          node {
            frontmatter {
              id
              name
              figures {
                id
                image {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                  }
                }
                sections {
                  sid
                  sectionText
                  sectionTitle
                  sectionVideo
                  sectionImage
                }
                text
                title
                type
                video
              }
            }
          }
        }
      }
    }
  `);

  const generalPollData = data.allMarkdownRemark.edges.find(
    (edge) => edge.node.frontmatter.id === 1
  ).node.frontmatter;
  const feedbackPollData = data.allMarkdownRemark.edges.find(
    (edge) => edge.node.frontmatter.id === 2
  ).node.frontmatter;
  const wordcloudPollData = data.allMarkdownRemark.edges.find(
    (edge) => edge.node.frontmatter.id === 3
  ).node.frontmatter;

  return (
    <Wrapper
      theme={theme}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.5, duration: 1 }}>
      <Content>
        <h2>Polls Guidelines</h2>
        <h3>
          As a moderator you have access to the Moderator&apos;s Control Panel from within the Polls
          feature of the livestream event. Here you can create, edit and open polls that encourage
          participation from viewers. You can then view or share the results with participants.
        </h3>
        {selectedPollDocumentation === 'general' && (
          <FigureWrapper>
            {generalPollData.figures.map((figure) => (
              <>
                {figure.title && <h4>{figure.title}</h4>}
                <Figure>
                  {figure.image && (
                    <GatsbyImage
                      image={getImage(figure.image)}
                      alt="Poll Guideline"
                      style={{ width: '100%', height: '100%' }}
                    />
                  )}
                  {figure.video && (
                    <iframe
                      title="vimeo-player"
                      src={figure.video}
                      width="100%"
                      height="260"
                      frameBorder="0"
                      allow="autoplay"
                    />
                  )}
                  {figure.sections &&
                    figure.sections.map((section) => (
                      <Option>
                        <ImageCaption dangerouslySetInnerHTML={{ __html: section.sectionText }} />
                        {section.sectionVideo && (
                          <iframe
                            title="vimeo-player"
                            src={section.sectionVideo}
                            height="340"
                            frameBorder="0"
                            allow="autoplay"
                          />
                        )}
                        {section.sectionImage && (
                          <GatsbyImage
                            image={getImage(section.sectionImage)}
                            alt="Poll Guideline"
                            style={{ width: '100%', height: '100%', maxWidth: '18.75rem' }}
                          />
                        )}
                      </Option>
                    ))}
                  {figure.text && (
                    <ImageCaption dangerouslySetInnerHTML={{ __html: figure.text }} />
                  )}
                </Figure>
              </>
            ))}
          </FigureWrapper>
        )}
        {selectedPollDocumentation === 'feedback' && (
          <FigureWrapper>
            {feedbackPollData.figures.map((figure) => (
              <>
                {figure.title && <h4>{figure.title}</h4>}
                <Figure>
                  {figure.image && (
                    <GatsbyImage
                      image={getImage(figure.image)}
                      alt="Poll Guideline"
                      style={{ width: '100%', height: '100%' }}
                    />
                  )}
                  {figure.video && (
                    <iframe
                      title="vimeo-player"
                      src={figure.video}
                      width="100%"
                      height="260"
                      frameBorder="0"
                      allow="autoplay"
                    />
                  )}
                  {figure.sections &&
                    figure.sections.map((section) => (
                      <Option>
                        <ImageCaption dangerouslySetInnerHTML={{ __html: section.sectionText }} />
                        {section.sectionVideo && (
                          <iframe
                            title="vimeo-player"
                            src={section.sectionVideo}
                            height="340"
                            frameBorder="0"
                            allow="autoplay"
                          />
                        )}
                        {section.sectionImage && (
                          <GatsbyImage
                            image={getImage(section.sectionImage)}
                            alt="Poll Guideline"
                            style={{ width: '100%', height: '100%', maxWidth: '18.75rem' }}
                          />
                        )}
                      </Option>
                    ))}
                  {figure.text && (
                    <ImageCaption dangerouslySetInnerHTML={{ __html: figure.text }} />
                  )}
                </Figure>
              </>
            ))}
          </FigureWrapper>
        )}
        {selectedPollDocumentation === 'wordcloud' && (
          <FigureWrapper>
            {wordcloudPollData.figures.map((figure) => (
              <>
                {figure.title && <h4>{figure.title}</h4>}
                <Figure>
                  {figure.image && (
                    <GatsbyImage
                      image={getImage(figure.image)}
                      alt="Poll Guideline"
                      style={{ width: '100%', height: '100%' }}
                    />
                  )}
                  {figure.video && (
                    <iframe
                      title="vimeo-player"
                      src={figure.video}
                      width="100%"
                      height="260"
                      frameBorder="0"
                      allow="autoplay"
                    />
                  )}
                  {figure.sections &&
                    figure.sections.map((section) => (
                      <Option>
                        <ImageCaption dangerouslySetInnerHTML={{ __html: section.sectionText }} />
                        {section.sectionVideo && (
                          <iframe
                            title="vimeo-player"
                            src={section.sectionVideo}
                            height="340"
                            frameBorder="0"
                            allow="autoplay"
                          />
                        )}
                        {section.sectionImage && (
                          <GatsbyImage
                            image={getImage(section.sectionImage)}
                            alt="Poll Guideline"
                            style={{ width: '100%', height: '100%', maxWidth: '18.75rem' }}
                          />
                        )}
                      </Option>
                    ))}
                  {figure.text && (
                    <ImageCaption dangerouslySetInnerHTML={{ __html: figure.text }} />
                  )}
                </Figure>
              </>
            ))}
          </FigureWrapper>
        )}
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled(motion.div)`
  align-items: center;
  color: ${({ theme }) => theme.bg};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 6.75rem;
  width: 100vw;

  h2 {
    margin: 0.5rem;
    padding: 0.5rem;
  }
  h3 {
    font-weight: normal;
    text-align: center;
    width: 50vw;
  }
  @media only screen and (max-width: 1024px) {
    h3 {
      width: 90vw;
    }
  }
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 3rem 0;
`;

const Option = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem;
  padding: 0 1rem;
  width: 100%;
  @media only screen and (max-width: 520px) {
    flex-direction: column;
  }
`;

const ImageCaption = styled.figcaption`
  padding: 1rem 0 2rem 0;
  width: 100%;
  small {
    opacity: 70%;
  }
`;

const Figure = styled.figure`
  align-items: center;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  width: 60vw;
  @media only screen and (max-width: 1024px) {
    width: 90vw;
  }
`;

const FigureWrapper = styled.div`
  margin: 3rem;
  figure {
    margin: 2rem 0;
  }
`;

export default PollDocumentation;
